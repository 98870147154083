import type { AppProps /*, AppContext */ } from "next/app";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import "@shopify/polaris/dist/styles.css";
import React from "react";
import { init } from "utils/sentry";
import { Provider } from "@shopify/app-bridge-react";
import { useRouter } from "next/router";
import { AmplitudeProvider, LogOnMount } from "react-amplitude-hooks";
import Head from "next/head";

init();

function MyApp({ Component, pageProps }: AppProps) {
  const router = useRouter();

  if (!router.isReady) return null;

  let shopOrigin = router.query.shop as string;
  if (!shopOrigin) {
    if (router.pathname !== "/install" && process.browser) {
      router.replace("/install");
    }
    shopOrigin = process.env.NEXT_PUBLIC_HOST as string;
  }

  const config = {
    apiKey: process.env.NEXT_PUBLIC_SHOPIFY_API_KEY as string,
    shopOrigin,
    forceRedirect: shopOrigin !== process.env.NEXT_PUBLIC_HOST,
  };

  const amplitude = require("amplitude-js");

  return (
    <>
      <Head>
        <script
          id="ze-snippet"
          src="https://static.zdassets.com/ekr/snippet.js?key=a1f83dba-61a4-4384-9b4c-d5648f6a6d06"
        >
          {" "}
        </script>
      </Head>
      <Provider config={config}>
        <AppProvider
          i18n={translations}
          theme={{
            // colors: {
            //   surface: '#1E3896',
            //   primary: '#1E3896',
            //   ''
            // },
            logo: {
              width: 248,
              url: "/apollotide-logo.svg",
              topBarSource: "/apollotide-logo.svg",
              contextualSaveBarSource: "/apollotide-logo.svg",
            },
          }}
        >
          <AmplitudeProvider
            amplitudeInstance={amplitude.getInstance()}
            apiKey={process.env.NEXT_PUBLIC_AMPLITUDE_API_KEY as string}
            userId={
              config.shopOrigin !== process.env.NEXT_PUBLIC_HOST
                ? config.shopOrigin
                : ''
            }
          >
            <LogOnMount eventType={"start trust badges pro"} />
            <Component {...pageProps} />
          </AmplitudeProvider>
        </AppProvider>
      </Provider>
    </>
  );
}

export default MyApp;
